.full-width-container{
width: 100%;
}

.full-height-container{
 height: 100%;
}

.build-name{
    color: transparent;
}


.flex-container-center{
    display: flex;
    /* margin: 1%; */
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    color:#212227;
}


.flex-container-start{
    display: flex;
    /* margin: 1%; */
    flex-direction: column;
    justify-content: start;
    align-items: start;    
}



@media only screen and (max-width: 900px)  {
    /* 여기에 모바일 화면에 적용할 스타일을 작성합니다 */    
.flex-container-center{
    display: flex;
    margin: 1%;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    color:#212227;
}
.flex-container-start{
    display: flex;
    margin: 1%;
    flex-direction: column;
    justify-content: start;
    align-items: start;    
    font-size: 0.8em;
}

  
  }
