
.container{
    margin: 1%;
 }

.step-container{
    margin-bottom: 1.5%;
    height: 5%;
    /* background-color: #bdd4e7; */
    display: flex;
    border-radius: 15px; /* 테두리를 동그랗게 만드는 속성 */
    color: #212227;
    }
    
.step-container>li{
    margin: 0.5%;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border-radius: 15px; /* 테두리를 동그랗게 만드는 속성 */
    font-size: 1.5em;
  
 }

 .main-container{
  height: 80%;
  margin-top: 1%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #cacaca;
  border-radius: 15px;
}
      
 
 /*
 *-------IdentityVerification---------------------------------------------------------
 */

 .loginImage-container{
  width: 30%;
  display: flex;
  border-radius: 10px;
  margin-bottom: 1%;
 }
 .loginImage-container>svg{
   height: 35px;
   width: 35px;
   margin: 1%;
   padding: 2%;
 }

 .loginImage-container>img{
   height: 55px;
 }

 .loginImage-container>span{
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 1.2em;
   font-weight: 500;
  
 }



 /*
 *-------ContentConfirmation---------------------------------------------------------
 */

 .withdrawal-bt{
  margin-top: 5%;
  font-size: 1.3em;
  font-weight: 600;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 2%;
  padding-right: 2%;
  color: #212227;
  /* background-color: #aab9cf; */
 }

 .service-name{
  padding: 0.5%;
  border-radius: 10px;
  /* background-color: #aab9cf; */
  color: #212227;
  margin-left: 0.5%;
  margin-right: 0.5%;
  font-size: 1em;
 }


 
 @media only screen and (max-width: 900px) {
  /* 여기에 모바일 화면에 적용할 스타일을 작성합니다 */
 
  .step-container{
    margin-bottom: 1.5%;
    height: 5%;
    background-color: #bdd4e7;
    display: flex;
    border-radius: 15px; /* 테두리를 동그랗게 만드는 속성 */
    color: #212227;
    }

        
  .step-container>li{
    margin: 0.5%;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    border-radius: 15px; /* 테두리를 동그랗게 만드는 속성 */
    font-size: 1.0em;

  }

  .loginImage-container{
    display: flex;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 2%;
   }

   .service-name{
    padding: 1%;
    border-radius: 10px;
    background-color: #aab9cf;
    color: #212227;
    margin-right: 1%;
    font-size: 0.8em;
    font-weight: bold;
   }

}