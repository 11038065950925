header{
  margin-top: 1%;
  margin-bottom: 1%;
  font-size: 2.5em;
  font-family: sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: sans-serif;}
ul{
  list-style: none;
  padding: 0;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label{
  color:#637074;
}

h1{
  display: flex;
  align-items: center;
  color: #212227;
}

h2{
  color: #212227;
}

button{
  border: none;
  border-radius: 10px;
}

input{
width: 45%;
margin-top: 0.2%;
margin-bottom: 0.2%;  
border: solid 0.1px;
border-radius: 5px;
border-color: #63707499;
}

input:focus {
  border: 2px solid #63707499; /* 포커스시 테두리 색상 변경 */
  outline: none; /* 기본 포커스 효과 제거 */
}

button:hover{
  cursor: pointer;
}

@media only screen and (max-width: 900px)  {
  header{
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 2.5em;
    font-family: sans-serif;
  }
}